import type { ButtonGroupProps } from '@chakra-ui/react';
import { Button, ButtonGroup, IconButton } from '@chakra-ui/react';
import type { PaginationMeta } from '@dmp/shared/types';
import {
    CaretLeft as IconPrevious,
    CaretRight as IconNext,
} from '@phosphor-icons/react';

import {
    allowToGo,
    calcNextPages,
    calcPreviousPages,
    showEnd,
    showStart,
} from './helpers';

interface PaginationProps extends ButtonGroupProps {
    isLoading?: boolean;
    pagination: Pick<PaginationMeta, 'current_page' | 'total_pages'>;
    goTo: (page: number) => void;
    halfSpan?: number;
}

export const Pagination = ({
    isLoading,
    goTo,
    halfSpan = 2,
    pagination,
    size = 'sm',
    ...rest
}: PaginationProps) => {
    if (pagination.total_pages < 2) {
        return null;
    }

    const { current_page, total_pages } = pagination;

    const previousPages = calcPreviousPages(current_page, halfSpan);
    const nextPages = calcNextPages(current_page, total_pages, halfSpan);
    const start = showStart(current_page, halfSpan);
    const end = showEnd(current_page, total_pages, halfSpan);

    const toPage = (pageNum: number) => {
        if (allowToGo(pageNum, current_page, total_pages)) goTo(pageNum);
    };

    return (
        <ButtonGroup size={size} disabled={isLoading} {...rest}>
            {current_page > 1 && (
                <IconButton
                    aria-label="previous page"
                    title="go to previous page"
                    width="80px"
                    icon={<IconPrevious />}
                    onClick={() => toPage(current_page - 1)}
                />
            )}

            {start && <Button onClick={() => toPage(1)}>1</Button>}
            {start && <Button>..</Button>}

            {previousPages.map((page, idx) => (
                <Button onClick={() => toPage(page)} key={idx}>
                    {page}
                </Button>
            ))}

            <Button disabled title="current page" variant="outline">
                {current_page}
            </Button>

            {nextPages.map((page, idx) => (
                <Button key={idx} onClick={() => toPage(page)}>
                    {page}
                </Button>
            ))}

            {end && <Button disabled>..</Button>}
            {end && (
                <Button onClick={() => toPage(total_pages)}>
                    {total_pages}
                </Button>
            )}

            {current_page < total_pages && (
                <IconButton
                    aria-label="next page"
                    title="go to next page"
                    width="80px"
                    icon={<IconNext />}
                    onClick={() => toPage(current_page + 1)}
                />
            )}
        </ButtonGroup>
    );
};
